<template>
  <div>
    <b-row>
      <b-col
        xl="4"
        cols="12"
      >
        <activity-adl-card />
      </b-col>
      <b-col
        xl="4"
        cols="12"
      >
        <sustenance-adl-card />
      </b-col>
      <b-col
        xl="4"
        cols="12"
      >
        <clothing-adl-card />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        xl="4"
        cols="12"
      >
        <hygiene-adl-card />
      </b-col>
      <b-col
        xl="4"
        cols="12"
      >
        <toilet-adl-card />
      </b-col>
      <b-col>
        <b-card
          style="background-color: rgba(85,221,146,0.2);"
          class="text-center"
        >
          <b-avatar
            class="mb-1"
            :variant="`success`"
            size="45"
          >
            <feather-icon
              size="21"
              icon="ActivityIcon"
            />
          </b-avatar>
          <div class="truncate">
            <h2 class="mb-25 font-weight-bolder">
              {{ $t('emma') }}
            </h2>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <emma-adl-overview />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BAvatar,
} from 'bootstrap-vue'
import ToiletAdlCard from '@/components/emma/adl/ToiletAdlCard.vue'
import SustenanceAdlCard from '@/components/emma/adl/SustenanceAdlCard.vue'
import HygieneAdlCard from '@/components/emma/adl/HygieneAdlCard.vue'
import ClothingAdlCard from '@/components/emma/adl/ClothingAdlCard.vue'
import ActivityAdlCard from '@/components/emma/adl/ActivityAdlCard.vue'
import EmmaAdlOverview from '@/components/emma/adl/EmmaAdlOverview.vue'

export default {
  name: 'ADLDashboard',
  components: {
    ToiletAdlCard,
    BCol,
    BRow,
    BCard,
    BAvatar,
    SustenanceAdlCard,
    HygieneAdlCard,
    ClothingAdlCard,
    ActivityAdlCard,
    EmmaAdlOverview,
  },
  data() {
    return {
      showDetailsADL1: false,
      showDetailsADL2: false,
      showDetailsADL3: false,
      showDetailsADL4: false,
      showDetailsADL5: false,
    }
  },
  computed: {
    selectedLocalGroup() {
      return this.$store.getters['localGroups/getLocalGroupById'](parseInt(this.localGroupId, 10))
    },
    localGroupId() {
      return this.$route.query.id
    },
  },
}
</script>

<style scoped>

</style>
