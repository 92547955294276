<template>
  <div>
    <b-row>
      <b-col>
        <emma-adl-bar-graph
          :amount-of-days="14"
          :max-value="20"
          data-label="Anzahl Dialoge"
          :title="$t('dialogsWithEmma')"
          color="#97c0d6"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <emma-adl-bar-graph
          :amount-of-days="14"
          :max-value="100"
          data-label="Anzahl Bewegungen"
          :title="$t('motionDetected')"
          color="#668caa"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <emma-adl-bar-graph
          :amount-of-days="14"
          :max-value="6"
          data-label="Anzahl Medikamentenbestätigungen"
          :title="$t('medicationConfirmed')"
          color="#363f58"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <emma-adl-bar-graph
          :amount-of-days="14"
          :max-value="20"
          data-label="Anzahl Kommunkationen"
          :title="$t('communicationsWithEmma')"
          color="#f1e967"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import EmmaAdlBarGraph from '@/components/emma/adl/emma-adl-graphs/EmmaAdlBarGraph.vue'
import { BRow, BCol } from 'bootstrap-vue'

export default {
  name: 'EmmaAdlOverview',
  components: {
    EmmaAdlBarGraph, BRow, BCol,
  },
}
</script>

<style scoped>

</style>
