<template>
  <b-card class="full-height-card">
    <div>
      <b-card-header class="d-flex justify-content-between align-items-center">
        <div class="truncate">
          <h1 class="mb-25 font-weight-bolder">
            {{ title }}
          </h1>
        </div>
      </b-card-header>
      <b-card-body>
        <chartjs-component-bar-chart
          :key="id"
          :data="chartData"
          :options="chartOptions"
          :plugins="plugins"
        />
      </b-card-body>
    </div>
  </b-card>
</template>

<script>
import {
  BCardBody, BCard, BCardHeader,
} from 'bootstrap-vue'
import { $themeColors, $chartColors } from '@themeConfig'
import ChartjsComponentBarChart from '../../../charts/ChartjsComponentBarChart.vue'

export default {
  name: 'OfflineEmmasGraph',
  components: {
    ChartjsComponentBarChart,
    BCardBody,
    BCard,
    BCardHeader,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    dataLabel: {
      type: String,
      required: true,
    },
    maxValue: {
      type: Number,
      required: true,
    },
    amountOfDays: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      id: 0,
      plugins: [
        // to add spacing between legends and chart
        {
          beforeInit(chart) {
            /* eslint-disable func-names, no-param-reassign */
            chart.legend.afterFit = function () {
              this.height += 20
            }
            /* eslint-enable */
          },
        },
      ],
    }
  },
  computed: {
    chartOptions() {
      return {
        elements: {
          rectangle: {
            borderWidth: 2,
            borderSkipped: 'bottom',
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 500,
        legend: {
          display: false,
        },
        tooltips: {
          // Updated default tooltip UI
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: $chartColors.tooltipShadow,
          backgroundColor: $themeColors.light,
          titleFontColor: $themeColors.dark,
          bodyFontColor: $themeColors.dark,
          callbacks: {
            label(context) {
              return `${context.value}`
            },
          },
        },
        interaction: {
          mode: 'nearest',
        },
        scales: {
          xAxes: [
            {
              display: true,
              gridLines: {
                display: true,
                color: $chartColors.grid_line_color,
                zeroLineColor: $chartColors.grid_line_color,
              },
              scaleLabel: {
                display: false,
              },
              ticks: {
                fontColor: $chartColors.labelColor,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              gridLines: {
                color: $chartColors.grid_line_color,
                zeroLineColor: $chartColors.grid_line_color,
              },
              scaleLabel: {
                display: true,
                labelString: this.dataLabel,
              },
              ticks: {
                stepSize: 10,
                min: 0,
                max: this.maxValue,
                fontColor: $chartColors.labelColor,
              },
            },
          ],
        },
      }
    },
    chartData() {
      const labels = []
      const dataset = []

      for (let i = 0; i < this.amountOfDays; i += 1) {
        dataset.push(Math.floor(Math.random() * this.maxValue))
      }

      for (let i = 0; i < this.amountOfDays; i += 1) {
        const date = new Date().setDate(new Date().getDate() - i)
        labels.push(this.formatTimestamp(date))
      }

      labels.reverse()

      const colors = []
      for (let i = 0; i < dataset.length; i += 1) {
        colors.push(this.color)
      }

      return {
        labels,
        datasets: [
          {
            data: dataset,
            borderColor: colors,
            backgroundColor: colors,
          },
        ],
      }
    },

  },
  mounted() {
    this.id += 1
  },
  methods: {
    formatTimestamp(timestamp) {
      const date = new Date(timestamp)
      const day = new Date(date).getDate()
      const month = new Date(date).getMonth() + 1
      return `${day.toString()}.${month.toString()}`
    },
  },
}
</script>

<style scoped>

.full-height-card {
  height: calc(100% - 2rem);
}
</style>
