<template>
  <b-card
    style="background-color: rgba(85,221,146,0.2);"
    class="text-center"
    @click="showDetails = !showDetails"
  >
    <b-avatar
      class="mb-1"
      :variant="`success`"
      size="45"
    >
      <feather-icon
        size="21"
        icon="ActivityIcon"
      />
    </b-avatar>
    <div class="truncate">
      <h2 class="mb-25 font-weight-bolder">
        {{ $t('walkToToilet') }}
      </h2>
    </div>
    <div
      v-if="showDetails"
      class="mt-3"
    >
      <b-row>
        <b-col md="4">
          <statistic-card-vertical
            style="background-color: rgba(255,255,255,0.9); height: calc(100% - 2rem)"
            statistic="Erkannt"
            statistic-title="Bewegung in Toilette bzw Nassraum"
            icon="ActivityIcon"
            color="success"
          />
        </b-col>
        <b-col md="4">
          <statistic-card-vertical
            style="background-color: rgba(255,255,255,0.9); height: calc(100% - 2rem)"
            statistic="Betätigt"
            statistic-title="Klospülung"
            icon="ActivityIcon"
            color="success"
          />
        </b-col>
        <b-col md="4">
          <statistic-card-vertical
            style="background-color: rgba(255,255,255,0.9); height: calc(100% - 2rem)"
            statistic="Betätigt"
            statistic-title="Waschbecken"
            icon="ActivityIcon"
            color="success"
          />
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import {
  BCard, BRow, BCol, BAvatar,
} from 'bootstrap-vue'

export default {
  name: 'ToiletADLCard',
  components: {
    BCard, BRow, BCol, BAvatar, StatisticCardVertical,
  },
  data() {
    return {
      showDetails: false,
    }
  },
}
</script>

<style scoped>

</style>
